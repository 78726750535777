<template lang="pug">
	div.page-reports
		reports-widget(:config="reportsConfig", :offer_id="filters.offer_id", :placement_id="filters.placement_id", :complex_offer_id="filters.complex_offer_id")
</template>
<script>
export default {
  name: 'Reports',
  async beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      vm.filters.offer_id = to.query.offer_id || '';
      vm.filters.complex_offer_id = to.query.complex_offer_id || '';
      vm.filters.placement_id = to.query.placement_id || '';
      return vm;
    });
  },
  data() {
    return {
      filters: {
        offer_id: '',
        complex_offer_id: '',
        placement_id: '',
      },
      reportsConfig: {
        loadOnStart: true,
      },
    };
  },
};
</script>
